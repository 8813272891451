
export const defaultLocale = "en";
export const localeOptions=[
    {id:'en',name:'English'},
    {id:'es',name:'Español'},
];

export const alertType = { 
    1: "Email",
    2: "Skype",
    3: "Telegram",
    4: "Web push",
    5: "Webhook",
    6: "Message",
    7: "Call",
    8: "Slack",
    9: "Zappier webhook"
}
