<template>
  <!-- Page Header Start-->
  <div class="page-main-header" :class="{ open: !togglesidebar }">
    <div class="main-header-right row">

      <div class="mobile-sidebar d-block" v-if="layout.settings.sidebar.type != 'horizontal_sidebar'">
        <div class="media-body switch-sm">
          <label class="switch">
            <span @click="toggle_sidebar">
              <feather type="menu"></feather>
            </span>
            <!-- <input id="sidebar-toggle" type="checkbox" checked="checked"><span class="switch-state" @click="toggle_sidebar"></span> -->
          </label>
        </div>
      </div>
      <div class="main-header-left d-lg-none">
        <div class="logo-wrapper">
          <router-link to="/">
            <img src="../assets/images/makeping-logo.png" alt="">
          </router-link>
        </div>
      </div>
      <div class="vertical-mobile-sidebar"><i class="fa fa-bars sidebar-bar"></i></div>

      <div class="nav-right left-menu-header custom-col hideMobileNav">
        <ul class="nav-menus-left" :class="{ open: mobiletoggle1 }">
          <li class="breadcrumbHeader col">
            <span class="custom-font-30 f-w-600">{{ $route.meta.title }}</span>
            <ol class="breadcrumb custom-font-18">
              <li class="breadcrumb-item" v-if="user_role == 'manager'"><router-link class="text-dark"
                  :to="{ path: '/manager/users' }">Home</router-link></li>
              <li class="breadcrumb-item" v-else><router-link class="text-dark"
                  :to="{ path: '/app/dashboard' }">Home</router-link></li>
              <li class="breadcrumb-item active">{{ $route.meta.title }}</li>
            </ol>
          </li>

          <li v-if="user.role == 'user'">
            <span v-if="(endAfterStart(new Date(), plan_details.expiry_date) == false) && plan_details.plan_type != 1"
              class="text-danger text-right custom-font-16">
              Your Plan <span class="font-weight-bold">{{ plan_details.plan_name }}</span> is Expired. Please upgrade
              your
              plan.
            </span>
          </li>

          <li class="" v-if="user.role == 'user'"><button id="upgrade_btn"
              class="custom-font-22 add-monitor-btn-green border-0" @click="upgrade_modal = true">Upgrade</button>
          </li>
        </ul>
      </div>

      <div class="nav-right left-menu-header custom-col showMobileNav">
        <ul class="nav-menus-left" :class="{ open: mobiletoggle1 }">
          <li class="breadcrumbHeader col">
            <span class="custom-font-30 f-w-600">{{ $route.meta.title }}</span>
            <ol class="breadcrumb custom-font-18">
              <li class="breadcrumb-item" v-if="user_role == 'manager'"><router-link class="text-dark"
                  :to="{ path: '/manager/users' }">Home</router-link></li>
              <li class="breadcrumb-item" v-else><router-link class="text-dark"
                  :to="{ path: '/app/dashboard' }">Home</router-link></li>
              <li class="breadcrumb-item active">{{ $route.meta.title }}</li>
            </ol>
          </li>

          <li class="" v-if="user.role == 'user'"><button id="upgrade_btn"
              class="custom-font-22 ml-2 add-monitor-btn-green border-0" @click="upgrade_modal = true">Upgrade</button>
          </li>

          <li>
            <span class="media col user-header">
              <img class="mr-2 rounded-circle img-40" src="../assets/images/dashboard/user.png" alt="">
              <div class="media-body">
                <span class="custom-font-14 f-w-600">{{ user.email }}</span>
                <br>
                <span class="d-block custom-font-14" v-if="user.role == 'user' && plan_details.plan_type != 1">Next
                  billing date : {{
                    changeDateFormate(plan_details.expiry_date) }}
                </span>
                <span v-else class="d-block custom-font-16">{{ user.role }}</span>
              </div>
            </span>
          </li>
        </ul>
      </div>

      <div class="nav-right custom-col-2 pull-right right-menu">
        <ul class="nav-menus">
          <!-- <li>
                <form class="form-inline search-form"><span class="mobile-search search-icon" @click="search_open()"><i class="fa fa-search"></i></span></form>
              </li> -->
          <li>
            <div class="dropdown">
              <b-dropdown id="dropdown-1" class="profile-dropdown">
                <template v-slot:button-content>
                  <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="media user-header">
                      <img class="mr-2 mt-1 rounded-circle img-40" src="../assets/images/dashboard/user.png" alt="">
                      <div class="media-body">
                        <span class="custom-font-16 f-w-600">{{ user.email }}</span>
                        <br>
                        <span class="d-block custom-font-16"
                          v-if="user.role == 'user' && plan_details.plan_type != 1">Next billing date : {{
                            changeDateFormate(plan_details.expiry_date) }}
                        </span>
                        <span v-else class="d-block custom-font-16">{{ user.role }}</span>
                      </div>
                    </span>
                  </button>
                </template>
                <!-- <b-dropdown-item class="gradient-primary-1">
                        <h6 class="mb-0">{{user.email}}</h6><span>{{user.role}}</span>
                    </b-dropdown-item> -->
                <!-- <b-dropdown-item class="text-left"><feather type="user"></feather>Profile</b-dropdown-item> -->
                <!-- <b-dropdown-item><feather type="message-square"></feather>Inbox</b-dropdown-item>
                    <b-dropdown-item><feather type="file-text"></feather>Taskboard</b-dropdown-item>
                    <b-dropdown-item><feather type="settings"></feather>Settings</b-dropdown-item> -->
                <b-dropdown-item class="text-left" v-if="user.role == 'user'">
                  <router-link class="text-dark" :to="{ path: '/app/profile' }">
                    <feather type="user"></feather>My Profile
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item class="text-left" @click="logout()">
                  <feather type="log-out"></feather>Logout
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
        </ul>
      </div>
      <form class="form-inline search-full" :class="{ open: searchOpen }">
        <div class="form-group form-control-plaintext">
          <input :class="{ open: mobile_search }" v-on:keyup="searchTerm" v-model="terms" type="text"
            placeholder="Search..">
          <div :class="searchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'" v-if="menuItems.length">
            <div class="ProfileCard u-cf" v-for="(menuItem, index) in menuItems.slice(0, 8)" :key="index">
              <div class="ProfileCard-avatar header-search">
                <feather :type="menuItem.icon"></feather>
              </div>
              <div class="ProfileCard-details">
                <div class="ProfileCard-realName">
                  <span @click='removeFix()'><router-link :to="{ path: menuItem.path }" class="realname">{{
                      menuItem.title
                      }}</router-link></span>
                </div>
              </div>
            </div>
          </div>
          <div :class="searchResultEmpty ? 'Typeahead-menu is-open' : 'Typeahead-menu'">
            <div class="tt-dataset tt-dataset-0">
              <div class="EmptyMessage">
                Your search turned up 0 results. Opps There are no result found.
              </div>
            </div>
          </div>
          <i class="icon-close close-search" @click="search_close()"></i>
        </div>
      </form>
      <div class="d-lg-none mobile-toggle pull-right" @click="openmobilemenu1">
        <feather type="user"></feather> <i data-feather="more-horizontal"></i>
      </div>
    </div>
    <b-modal id="modal-monitor" title="Plan Details" size="xl" class="theme-modal" @hidden="(upgrade_modal = false)"
      v-model="upgrade_modal" no-close-on-backdrop hide-footer>
      <!-- <create-plan :create_plan_modal="create_plan_modal" @planmodal="closeModal" :edit_plan="edit_plan" :plan_data="plan_data"></create-plan> -->
      <upgrade-component></upgrade-component>
      <!-- <template #modal-footer>
        <b-button @click="upgrade_modal = false" color="danger">Close</b-button>
        </template> -->
    </b-modal>
  </div>
  <!-- Page Header Ends -->
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
import UserAuth from '../auth/js/index'
import UpgradeComponent from '../views/UpgradePlan.vue'
import UserController from "../../services/userController";
import DashboardController from "../../services/dashboardController";
import moment from 'moment';
export default {
  name: 'Search',
  components: { UpgradeComponent },
  data() {
    return {
      upgrade_modal: false,
      terms: '',
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      mobiletoggle1: false,
      mobiletoggle2: false,
      user: {},
      plan_details: {},
      downMonitorCounts: 5,
      user_role: '',
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    this.user_role = this.user.role
    this.getPlanInfo();
    if (this.user.role == 'user' || this.user.role == 'subUser') {
     
      //run get data in every 1 minutes
      this.getData();
      setInterval(() => {
        this.getData();
      }, 60000);
    }
  },
  computed: {
    
    ...mapState({
      menuItems: state => state.menu.searchData,
      layout: state => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar
    }),
    isMobileScreen() {
      return window.innerWidth <= 1200;
    }
  },
  // mounted() {
  //   // this.checkPlan()
  // },
  methods: {
    async checkPlan() {
      let response = await UserController.getPlanInfo();
      if (response.result) {
        let plan = response.message 
        let plan_type = plan.plan_type
        let expiry_date = new Date(plan.expiry_date)
        let today = new Date()
        if (plan_type == 1 || today >= expiry_date) {
          this.upgrade_modal = true
        }
      }
    },
    async getData() {
      this.checkPlan()
      let response = await DashboardController.getMonitorReport();
      if (response.message) {
        this.downMonitorCounts = response.data.down;
      }
      const faviconElement = document.getElementById("dynamic-favicon");
      let faviconUrl;
      const notificationCount = this.downMonitorCounts;
      if (notificationCount > 20) {
        faviconUrl = '/icon/20_plus.png';
      }
      else if (notificationCount > 0) {
        faviconUrl = `/icon/${notificationCount}.png`;
      }
      else {
        faviconUrl = '/favicon.png';
      }
      faviconElement.href = faviconUrl;
    },
    changeDateFormate(d) {
      return moment(d).format('DD/MM/YYYY')
    },
    endAfterStart(start, end) {
      var startDate = new Date(start);
      var endDate = new Date(end);

      return endDate.getTime() >= startDate.getTime();
    },
    async getPlanInfo() {
      let response = await UserController.getPlanInfo();
      if (response.result) {
        this.plan_details = response.message;
        this.$root.$data.plan_details = this.plan_details
      }
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    openmobilemenu1() {
      this.mobiletoggle1 = !this.mobiletoggle1
    },
    openmobilemenu2() {
      this.mobiletoggle2 = !this.mobiletoggle2
    },
    search_open() {
      this.searchOpen = true
    },
    search_close() {
      this.searchOpen = false
    },
    searchTerm: function () {
      this.$store.dispatch('menu/searchTerm', this.terms)
    },
    logout: function () {
      // firebase.auth().signOut().then(() => {
      UserAuth.Logout();
      this.$router.replace('/auth/login')
      // })
    },
    addFix() {
      body.classList.add("offcanvas")
      this.searchResult = true;
    },
    removeFix() {
      router
      body.classList.remove("offcanvas")
      this.searchResult = false;
      this.terms = ''
    },
    // toggle_sidebar(){
    //   this.toggle_sidebar_var = !this.toggle_sidebar_var,
    //   this.clicked = !this.toggle_sidebar_var,
    //   this.$emit('clicked',this.clicked)
    // },
    toggle_rightsidebar() {
      this.toggle_rightsidebar_var = !this.toggle_rightsidebar_var,
        this.rightclicked = !this.toggle_rightsidebar_var,
        this.$emit('rightclicked', this.rightclicked)
    },
    toggle_fullscreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    async visitedUser() {
      let response = await UserController.visitedUser()
    }
  },
  watch: {
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length)
        this.searchResultEmpty = true;
      else
        this.searchResultEmpty = false;
    },
    upgrade_modal() {
      if (this.upgrade_modal == true) {
        this.visitedUser()
      }
    }
  }
}
</script>

<style scoped>
.page-main-header .main-header-right .nav-right>ul>li:before {
  background: none;
}

/* .page-main-header .main-header-right .nav-right .profile-dropdown ul {
  width: unset !important;
} */

.custom-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}

.showMobileNav {
  display: none;
}

@media screen and (max-width: 767.98px) {
  .showMobileNav {
    display: block;
  }
  .hideMobileNav {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .custom-col-2 {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .page-main-header .main-header-right .nav-right.right-menu ul li .user-header .media-body {
    display: block !important;
    padding-left: 10px;
  }
}

.page-main-header .main-header-right .nav-right .profile-dropdown ul li:first-child {
  padding: 12px !important;
}

.left-menu-header {
  width: 56vw;
}
</style>

<style>
.page-wrapper .page-main-header {
  height: 110px !important;
  align-items: start !important;
}
.page-wrapper .page-body-wrapper .page-body {
  margin-top: 110px !important;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .page-wrapper .page-main-header {
    height: 95px !important;
    align-items: start !important;
  }
  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 95px !important;
  }
}


@media (max-width: 768px) {
  .page-wrapper .page-main-header {
    height: 60px !important;
    align-items: start !important;
  }

  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 60px !important;
  }
}
</style>
